<template>
  <el-dialog :visible.sync="dialogVisible" custom-class="custom-dialog emailSubscriptionSet"
    :modal-append-to-body="false">
    <div class="head" slot="title">
      <h2 class="title">{{ $t('用户通知邮件状态') }}</h2>
      <p class="desc">{{ $t('设置不同操作开启通知') }}</p>
    </div>
    <div class="body">
      <el-form ref="form">
        <el-form-item>
          <div class="form-item-content">
            <div>{{ $t("通知邮件") }}</div>
            <div>
              <el-switch v-model="form.auto_mail_notify_sta" @change="change('auto_mail_notify_sta')"/>
            </div>
          </div>
        </el-form-item>
        <el-form-item>
          <div class="form-item-content">
            <div>{{ $t("营销邮件") }}</div>
            <div>
              <el-switch v-model="form.admin_mail_notify_sta" @change="change('admin_mail_notify_sta')"/>
            </div>
          </div>
        </el-form-item>
      </el-form>
    </div>
  </el-dialog>
</template>

<script>
import { my_check_email_notify_sta_api } from "@/api/user"
export default {
  data() {
    return {
      dialogVisible: false,
      form: {
        auto_mail_notify_sta: true,
        admin_mail_notify_sta: true,
      }
    }
  },
  methods: {
    show() {
      this.dialogVisible = true
    },
    async change(type) {
      this.$store.state.userInfo.auto_mail_notify_sta = this.form.auto_mail_notify_sta ? 1 : 2
      this.$store.state.userInfo.admin_mail_notify_sta = this.form.admin_mail_notify_sta ? 1 : 2
      my_check_email_notify_sta_api({ type, status: this.form[type] ? 1 : 2})
    }
  },
  mounted() {
    this.form.auto_mail_notify_sta = this.$store.state.userInfo.auto_mail_notify_sta == 1
    this.form.admin_mail_notify_sta = this.$store.state.userInfo.admin_mail_notify_sta == 1
  }
}
</script>
<style scoped>
:deep(.emailSubscriptionSet) {
  max-width: 360px !important;
}

.head {
  position: relative;
  padding-top: 10px;
}

.title {
  font-size: 24px;
  text-align: center;
}

.desc {
  display: block;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #8e939e;
  text-align: center;
}


.form-item-content {
  display: flex;
  align-items: center;
  color: #FFF;
  justify-content: center;
  margin-bottom: 14px;
}

.form-item-content div {
  text-align: center;
  line-height: 1.4;
  flex: 1;
}
</style>
